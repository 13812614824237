import React, { useState, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { useAuth } from "../../user/hooks/useAuth";
import { MenuTop } from "./MenuTop";
import { ToastContainer } from 'react-toastify';
import { Footer }  from "./Footer";
import { SPCookieConsent } from "./CookieConsent";

export const Layout = (props) => {
  const { user, setUser } = useAuth();
  const location = useLocation();
  const CLASS = user ? "card sp-container bg-white" : "card sp-container-2 bg-white";
  const [ assistant, setAssistant ] = useState(false);
  const [ containerWidth, setContainerWidth ] = useState(
    user ?
      (window.innerWidth > 768 ?
        window.innerWidth - 278 + "px":
        window.innerWidth - 40 + "px"):
        window.innerWidth - 40 + "px"
  );

  const handleResize = () => {
    if (user) {
      if ( window.innerWidth > 768) {
        setContainerWidth(window.innerWidth - 278 + "px")
      } else {
        setContainerWidth(window.innerWidth - 40 + "px");      
      }
    } else {
      setContainerWidth(window.innerWidth - 40 + "px");  
    }
  }

  useEffect(() => {
    handleResize();
  }, [user, window.innerWidth]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ToastContainer autoClose={2000} />
      <MenuTop
        setUser={setUser}
        user={user}
        private={props.private}
        setAssistant={setAssistant}
      />
      <div className={CLASS} style={{width: containerWidth}}>
        <Outlet context={[assistant, setAssistant, user, setUser]} />
      </div>
      <Footer user={user}/>
      { location.pathname !== "/cookies" &&
        <SPCookieConsent/>
      }
      
    </>
  )
};